import React from 'react';
import { Form } from 'react-bootstrap';

const ContactForm = () => {
  const [state, setState] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const postSubmit = () => {
    setSubmitted(true);
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then()
      .catch((error) => alert(error));
    postSubmit();
  };

  return (
    <>
      {!submitted &&
        <Form
          name="Generativ Social Impact"
          method="post"
          action={"/social-impact/"}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}              
        >
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" 
                          name="name"
                          placeholder="Name"
                          required
                          onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" 
                          name="email"
                          placeholder="Email"
                          required
                          onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group controlId="formType">
            <Form.Label>
              What kind of organization are you?
            </Form.Label>
            <Form.Control
              as="select"
              defaultValue={'default'}
              className="custom-select"
              name="organizationType"
              required
              onChange={handleChange}
            >
              <option disabled value="default">
                What kind of organization are you?
              </option>
              <option value="nonprofit">Nonprofit</option>
              <option value="bCorp">B Corp</option>
              <option value="ngo">NGO</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formLink">
            <Form.Label>Link to your site or pitch deck</Form.Label>
            <Form.Control
              type="text"
              name="link"
              placeholder="Link to your site or pitch deck"
              onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group controlId="formLink">
            <Form.Label>What can Generativ help you with?</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              placeholder="What can Generativ help you with?"
              required
              onChange={handleChange}
            />
          </Form.Group>

          <button type="submit" className="btn btn-primary w-50">
            Submit
          </button>

          {/* Need to add the hidden input with the form name to your JSX form */}
          <input type="hidden" name="form-name" value="contact"/>
        </Form>
      }

      {submitted &&
        <h2 className="text-center">Thanks for reaching out. Someone will get back to you shortly.</h2>
      }
    </>
  );
};
export default ContactForm;