import React from 'react';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../globals';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Styles from './social-impact.styles';
import ContactForm from '../components/contactForm/contactForm';

const SocialImpactPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="Social Impact" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>Empowering activists to do the most good</h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <section>
          <Container>
            <Row>
              <Col xs={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 0 }}>
                <h2>
                  Big issues need big ideas. We help activists amplify their
                  efforts with impactful technical solutions.
                </h2>

                <p>
                  Agents of change are working harder (and stretched thinner)
                  than ever. Our pro-bono projects aim to share the startup
                  expertise that will help them to make an even bigger impact.
                </p>

                <div className="d-none d-lg-block">
                  <Link to="/work/">See Our Work</Link>

                  <Link to="/about/">More About Us</Link>
                </div>
              </Col>

              <Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                <div className="form-container p-lg-4">
                  <h3 className="mb-3">How Can We Help You Help Others?</h3>
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <hr className={'mt-5 mt-md-6'} />
      </Styles>
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-social-impact.jpg" }) {
      ...imageFluidExtraLarge
    }
  }
`;

export default SocialImpactPage;
