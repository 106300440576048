import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';
import responsive from '../utils/responsive';
import colors from '../styles/modules/colors.module.scss';

const Styles = styled.main({
  // Todo: Dry out landing page styles
  section: {
    h2: {
      marginTop: `${rhythm(2)}`,
      marginBottom: `${rhythm(1 / 2)}`,
    },

    p: {
      marginBottom: `${rhythm(3 / 2)}`,
    },

    a: {
      display: 'inline-block;',
      marginRight: `${rhythm(1)}`,

      '&:last-child': {
        marginRight: 0,
      },
    },

    '.form-container': {
      borderRadius: `${rhythm(1 / 8)}`,
      overflow: 'hidden',
      backgroundColor: colors.white,
    },
  },

  [responsive.Mobile]: {
    section: {
      paddingBottom: 0,
    },

    '.form-container': {
      marginTop: 0,
    },
  },

  [responsive.Desktop]: {
    paddingBottom: `${rhythm(4)}`,

    '.form-container': {
      marginTop: `-${rhythm(2)}`,
    },
  },
});

export default Styles;
